import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
    faPlus,
    faPlusCircle,
    faQrcode,
    faImage,
    faTrash,
    faCheck,
    faCheckSquare,
    faTimes,
    faLock,
    faLockOpen,
    faSearch,
    faEllipsisH,
    faBars,
    faSpinner,
    faCaretDown,
    faLayerGroup,
    faMinusCircle,
    faExclamationCircle,
    faPenSquare,
    faTh,
    faList,
    faTimesCircle,
    faUpload,
    faGlobe,
    faBook,
    faFlask,
    faCode,
    faCopy,
    faSortAlphaDown,
    faSortAlphaUp,
    faEye,
    faEyeSlash,
    faExternalLinkAlt,
    faCamera,
    faFileDownload,
    faSun,
    faMoon,
    faDesktop,
    faCircleNotch,
    faCircleCheck,
    faTriangleExclamation,
    faFileLines,
    faVideoSlash,
    faChevronRight,
    faSlash,
    faAlignLeft,
    faMobileScreen,
    faTabletScreenButton,
    faDisplay,
    faArrowUpLong,
} from '@fortawesome/free-solid-svg-icons'

import {
    faStar,
    faPaperPlane,
    faCalendar
} from '@fortawesome/free-regular-svg-icons'

import {
    faGithubAlt,
    faOpenid
} from '@fortawesome/free-brands-svg-icons'

library.add(
    faPlus,
    faPlusCircle,
    faQrcode,
    faImage,
    faTrash,
    faCheck,
    faCheckSquare,
    faTimes,
    faLock,
    faLockOpen,
    faSearch,
    faEllipsisH,
    faBars,
    faSpinner,
    faGithubAlt,
    faCaretDown,
    faLayerGroup,
    faMinusCircle,
    faExclamationCircle,
    faPenSquare,
    faTh,
    faList,
    faTimesCircle,
    faUpload,
    faGlobe,
    faBook,
    faFlask,
    faCode,
    faCopy,
    faSortAlphaDown,
    faSortAlphaUp,
    faEye,
    faEyeSlash,
    faExternalLinkAlt,
    faCamera,
    faFileDownload,
    faSun,
    faMoon,
    faDesktop,
    faCircleNotch,
    faCircleCheck,
    faTriangleExclamation,
    faFileLines,
    faVideoSlash,
    faStar,
    faChevronRight,
    faOpenid,
    faPaperPlane,
    faSlash,
    faAlignLeft,
    faMobileScreen,
    faTabletScreenButton,
    faDisplay,
    faCalendar,
    faArrowUpLong,
);

export default FontAwesomeIcon